import * as React from "react";
import { useEffect, useState } from "react";

import { useModal } from "@tiller-ds/alert";

import { AuthProvider, AuthService } from "react-oauth2-pkce";

import { getCurrentUser } from "./api/getCurrentUser";
import AuthorizationErrorHandlerPage from "./pages/AuthorizationErrorHandlerPage";
import ExtendSessionModal from "./pages/ExtendSessionModal";
import LandingPage from "./pages/LandingPage";
import LicenceAgreementPage from "./pages/LicenceAgreementPage";
import { AthleteBriefResponse } from "../archive/athlete/api/AthleteBriefResponse";
import { UserType } from "../archive/common/api/UserResponse";
import { getOwnChildren } from "../archive/parent/api/getOwnChildren";
import LoadingCircle from "../common/components/LoadingCircle";
import { SESSION_EXTEND_MODAL_OPEN_AT_MINUTES_LEFT } from "../common/constants";
import { useEnvironmentVariable } from "../env-provider/EnvironmentVariablesProvider";

type SecurityWrapperProps = {
  children: React.ReactNode;
};

export const UserContext = React.createContext({
  user: { loaded: false } as CurrentUser,
  onChangeUser: (user: CurrentUser) => {},
  onChangeNumOfUnreadMessages: (numOfUnreadMessages: number) => {},
  userHasRole: (role: UserType): boolean => false,
  userHasAnyRole: (role: UserType[]): boolean => false,
  onChangeNumOfUnpaidInvoices: (numOfUnpaidInvoices: number) => {},
});

export type CurrentUser = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  userType: UserType;
  numOfUnreadMessages: number;
  loaded: boolean;
  hasAcceptedEula: boolean;
  allowedEmail: boolean;
  numOfUnpaidInvoices?: number;
};

export default function SecurityProvider({ children }: SecurityWrapperProps) {
  const {
    REACT_APP_CLIENT_ID,
    REACT_APP_PROVIDER_URL,
    REACT_APP_REDIRECT_URI,
    REACT_APP_SIGNOUT_URI,
  } = useEnvironmentVariable();

  const authService = new AuthService({
    clientId: REACT_APP_CLIENT_ID,
    provider: REACT_APP_PROVIDER_URL + "/oauth2",
    redirectUri: REACT_APP_REDIRECT_URI,
    location: window.location,
    scopes: ["openid", "aws.cognito.signin.user.admin"],
  });

  const [user, setUser] = useState({ loaded: false } as CurrentUser);
  const [userFetchErrorStatus, setUserFetchErrorStatus] = useState(null);
  const [userIsLoading, setUserIsLoading] = useState(true);

  const extendSessionModal = useModal();

  function onChangeNumOfUnreadMessages(numOfUnreadMessages: number) {
    setUser((prevState) => {
      return { ...prevState, numOfUnreadMessages: numOfUnreadMessages };
    });
  }

  function onChangeNumOfUnpaidInvoices(numOfUnpaidInvoices: number) {
    setUser((prevState) => {
      return { ...prevState, numOfUnpaidInvoices: numOfUnpaidInvoices };
    });
  }

  function onChangeUser(user: CurrentUser) {
    setUser(user);
  }

  function userHasRole(role: UserType) {
    return user.userType === role;
  }

  function userHasAnyRole(roles: UserType[]) {
    return roles.includes(user.userType);
  }

  const value = {
    user,
    onChangeUser,
    onChangeNumOfUnreadMessages,
    userHasRole,
    userHasAnyRole,
    onChangeNumOfUnpaidInvoices,
  };

  function refreshSession() {
    authService.armRefreshTimer(
      authService.getAuthTokens().refresh_token,
      1000
    );
  }

  useEffect(() => {
    if (authService.isAuthenticated() && !user.loaded) {
      setUserIsLoading(true);

      const timer = setInterval(() => {
        const diff = Math.abs(
          authService.getAuthTokens().expires_at! - Date.now()
        );
        //miliseconds to seconds
        const minutes = Math.floor(diff / 1000 / 60);
        if (minutes === SESSION_EXTEND_MODAL_OPEN_AT_MINUTES_LEFT) {
          extendSessionModal.onOpen(undefined);
        }
      }, 60000);

      getCurrentUser().then(
        (response) => {
          setUser({ ...response, loaded: true });
          setUserIsLoading(false);
        },
        (status) => {
          setUserFetchErrorStatus(status);
          setUserIsLoading(false);
        }
      );
      return () => {
        clearInterval(timer);
      };
    }
    // eslint-disable-next-line
  }, [authService.isAuthenticated]);

  if (
    authService.getAuthTokens() &&
    authService.getAuthTokens().expires_at &&
    authService.getAuthTokens().expires_at! < Date.now()
  ) {
    window.location.assign("/logout");
  }

  if (window.location.pathname === "/logout") {
    let params = new URLSearchParams({
      client_id: REACT_APP_CLIENT_ID,
      logout_uri: REACT_APP_SIGNOUT_URI,
    });

    window.localStorage.removeItem("auth");
    window.localStorage.removeItem("pkce");
    window.localStorage.removeItem("preAuthUri");
    window.location.assign(REACT_APP_PROVIDER_URL + "/logout?" + params);
    return <LoadingCircle isLoading={true} />;
  }

  if (!authService.isAuthenticated() || authService.isPending()) {
    return <LandingPage authService={authService} isLoading={false} />;
  }

  if (userFetchErrorStatus) {
    return <AuthorizationErrorHandlerPage status={userFetchErrorStatus} />;
  }

  if (
    authService.isAuthenticated() &&
    !user.hasAcceptedEula &&
    !userIsLoading
  ) {
    return <LicenceAgreementPage />;
  }

  if (userIsLoading) {
    return <LandingPage authService={authService} isLoading={true} />;
  }

  if (user.userType === UserType.PARENT) {
    const status = 403 as any;
    getOwnChildren().then((children: AthleteBriefResponse[]) => {
      if (children.length === 0) {
        setUserFetchErrorStatus(status);
      }
    });
  }

  return (
    <>
      <AuthProvider authService={authService}>
        <UserContext.Provider value={value}>{children}</UserContext.Provider>
      </AuthProvider>
      <ExtendSessionModal
        modal={extendSessionModal}
        onExtendSession={() => {
          refreshSession();
          extendSessionModal.onClose();
        }}
      />
    </>
  );
}
