import React from "react";

import { Button } from "@tiller-ds/core";
import { LoadingIcon } from "@tiller-ds/icons";

import { AuthService } from "react-oauth2-pkce";

import { ReactComponent as HAVKLogoVertical } from "../../assets/img/HAVK_vertical.svg";
import { useEnvironmentVariable } from "../../env-provider/EnvironmentVariablesProvider";

type LandingPageProps = {
  authService: AuthService;
  isLoading: boolean;
};

export default function LandingPage({
  authService,
  isLoading,
}: LandingPageProps) {
  const login = async () => authService.login();
  const {
    REACT_APP_PROVIDER_URL,
    REACT_APP_CLIENT_ID,
    REACT_APP_REDIRECT_URI,
  } = useEnvironmentVariable();

  const signup = async () => {
    let params = new URLSearchParams({
      client_id: REACT_APP_CLIENT_ID,
      redirect_uri: REACT_APP_REDIRECT_URI,
      response_type: "code",
    });

    window.location.replace(REACT_APP_PROVIDER_URL + "/signup?" + params);
  };

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center min-h-[70vh] fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
          <div className="h-fit">
            <LoadingIcon size={20} />
          </div>
        </div>
      ) : (
        ""
      )}

      <div
        className={`h-screen flex flex-col justify-center align-middle bg-navy-50 ${
          isLoading ? "opacity-30" : ""
        }`}
      >
        <div className="flex flex-col">
          <div className="m-auto pb-10">
            <HAVKLogoVertical />
          </div>
          <div className="text-center flex flex-col justify-center space-y-2">
            <div>
              <Button onClick={login} className="w-60 " disabled={isLoading}>
                Prijava
              </Button>
            </div>

            <div>
              <Button
                variant="outlined"
                onClick={signup}
                className="w-60"
                disabled={isLoading}
              >
                Registracija
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
